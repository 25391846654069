import React, { Component } from "react";

import iconChecked from "../assets/icons/outline-check_box-24px.svg";
import iconUnChecked from "../assets/icons/outline-check_box_outline_blank-24px.svg";
import expandLess from "../assets/icons/expand_less.svg";
import expandMore from "../assets/icons/expand_more.svg";
import classNames from "classnames";
import styles from "./ViewOptionsPanel.module.scss"; // Copied from the Categories component. Lots of unused styles.

export default class ViewOptionsRow extends Component {
  constructor(props) {
    super(props);
    this.state = { showChildren: true };
  }
  toggleChildren = () => {
    const showChildren = this.state.showChildren;
    this.setState({ showChildren: !showChildren });
  };

  toggleChildVisibility = (assetState) => {
    this.props.toggleVisible(assetState, this.props.item.name);
    this.forceUpdate();
  };

  getVisibility = () => {
    const { subcategory, item, parent } = this.props;
    if (!subcategory) return false;
    let visibility =
      subcategory.assets[item.name] && subcategory.assets[item.name].visibility;
    if (parent) {
      // console.log("PARENT", subcategory.assets);
      visibility =
        subcategory.assets[parent.name] &&
        subcategory.assets[parent.name].children &&
        subcategory.assets[parent.name].children[item.name] &&
        subcategory.assets[parent.name].children[item.name].visibility;
    }
    return visibility;
  };

  render() {
    const {
      item,
      isChild,
      subcategory,
      toggleVisible,
      setActiveAsset
    } = this.props;
    const { showChildren } = this.state;

    const hasChildren = item.children
      ? Object.values(item.children).length > 1
      : false;

    return (
      <div
        key={item.name}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          paddingLeft: "24px"
        }}
      >
        <div
          style={{
            fontWeight: 700,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "5px"
          }}
          className={classNames(styles.sub_category_name)}
        >
          <div
            style={{
              width: isChild ? "211px" : "235px" // Match root div indentation in child asset.
            }}
          >
            {item.name}
            {hasChildren && (
              <img
                onClick={this.toggleChildren}
                src={!showChildren ? expandMore : expandLess}
                alt="expand"
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100px"
            }}
          >
            <img
              src={this.getVisibility() ? iconChecked : iconUnChecked}
              alt="Visible"
              onClick={() => {
                toggleVisible({
                  name: item.name,
                  visibility: this.getVisibility()
                });
              }}
            />
            <div>Visible</div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100px"
            }}
          >
            <img
              src={
                subcategory && subcategory.activeAsset === item.name
                  ? iconChecked
                  : iconUnChecked
              }
              alt="Active"
              onClick={() => {
                setActiveAsset(item);
              }}
            />
            <div>Active</div>
          </div>
        </div>
        {Object.values(item.children).map((child, i) => {
          return (
            showChildren && (
              <ViewOptionsRow
                parent={item}
                key={i}
                item={child}
                subcategory={subcategory}
                isChild={true}
                setActiveAsset={setActiveAsset}
                toggleVisible={this.toggleChildVisibility}
              />
            )
          );
        })}
      </div>
    );
  }
}
