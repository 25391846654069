import * as constants from "../constants/notifications";

export default function notifications(state = {}, action = {}) {
  switch (action.type) {
    case constants.DEV_MODE:
      return { ...state, dev_mode: action.payload };

    case constants.SUB_CATEGORY_HOVER:
      return { ...state, subCategoryHover: action.payload };

    case constants.SUB_CATEGORY_UN_HOVER:
      return { ...state, subCategoryHover: null };

    case constants.ROTATE_UPDATED:
      return { ...state, rotateDone: action.payload };

    case constants.VIEW_UPDATED:
      return {
        ...state,
        categories: state.categories || action.payload.categories,
        updateCategories: action.payload.updateCategories
      };

    default:
      return state;
  }
}
