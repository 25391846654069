import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import { patchSubCategory } from "../redux/actions/portableState";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./modals.scss";
import close from "../assets/icons/close.svg";
import { typesIcon } from "../types_icon";
const iconCatalog = require("../iconCatalog.json");

class IconPicker extends Component {
  state = {
    Data: {},
    generalData: []
  };

  getIconButton = (icon) => {
    return (
      <div
        key={icon}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}>
        <div
          style={{ margin: 20 }}
          onClick={() => {
            this.props.setIcon(icon);
          }}>
          <img
            width="40"
            height="40"
            src={typesIcon(icon)}
            alt={iconCatalog[icon].name}
          />
        </div>
      </div>
    );
  };
  getIconButtons = () => {
    const jsx = Object.keys(iconCatalog).map(this.getIconButton);
    return jsx;
  };
  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  initialState = () => {};

  handleOnChange = (e, i) => {};

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.close}
        contentLabel="Select Icon"
        className="Modal"
        overlayClassName="Overlay2">
        <div className="edit_subcategory_modal">
          <div className="Modal_header">
            <h2>{"Select Icon"}</h2>
            <img onClick={this.props.close} src={close} alt="Colse" />
          </div>
          <div className="Modal_body" style={{ minHeight: "600px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap"
              }}>
              {this.getIconButtons()}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

Modal.setAppElement("#root");

function mapStateToProps(state) {
  return {
    portableState: state.portableState
  };
}

export default connect(
  mapStateToProps,
  { patchSubCategory }
)(IconPicker);
