import React from 'react';
import classNames from 'classnames';
import styles from "./welcome_message.module.scss";

import close from '../assets/icons/close.svg';
import Rotation from '../assets/icons/3d_rotation.svg';
import Sphere from '../assets/icons/arrow2.png';


const WelcomeMessage = ({message, closeWelcomeMessage, show}) => (
  <div className={classNames(styles.WelcomeMessage, {[styles.hide]: !show})} >
    <div className={styles.WelcomeMessage_box}>
      <div className={styles.WelcomeMessage_header}>
        <h4>Kangas Alue-esittely</h4>
        <img onClick={closeWelcomeMessage} src={close} alt="close"/>
      </div>
      <div className={styles.WelcomeMessage_body}>
        <div className={styles.message_text}></div>
        <img src={Rotation} alt="Rotation"/>
        <h4>Pidä hiiren nappi pohjassa kääntääksesi näkymää</h4>
      </div>
      <div className={styles.WelcomeMessage_body}>
        <div className={styles.message_text}></div>
        <img src={Sphere} alt="Rotation"/>
        <h4>Napsauta nuolia vaihtaaksesi näkymää</h4>
      </div>
      <div className={styles.WelcomeMessage_footer}>
        <button onClick={closeWelcomeMessage}>Ok</button>
      </div>
    </div>
  </div>
)

export default WelcomeMessage;
