export const CATEGORY_CHOSEN = "CATEGORY_CHOSEN";
export const SUB_CATEGORY_CHOSEN = "SUB_CATEGORY_CHOSEN";
export const ASSET_VISIBILITY_SET = "ASSET_VISIBILITY_SET";
export const ACTIVE_ASSET_SET = "ACTIVE_ASSET_SET";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const PATCH_CATEGORY = "PATCH_CATEGORY";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
export const ADD_SUB_CATEGORY = "ADD__SUB_CATEGORY";
export const PATCH_SUB_CATEGORY = "PATCH_SUB_CATEGORY";
export const REMOVE_SUB_CATEGORY = "REMOVE_SUB_CATEGORY";
export const SET_HOTSPOT_COORDINATES = "SET_HOTSPOT_COORDINATES";
export const SET_KRPANO_COORDINATES = "SET_KRPANO_COORDINATES";
export const SET_KRPANO_SCENE = "SET_KRPANO_SCENE";
export const SET_CESIUM_TIME = "SET_CESIUM_TIME";
export const NEW_HASH = "NEW_HASH";
export const SET_STATE_FROM_CLOUD = "SET_STATE_FROM_CLOUD";