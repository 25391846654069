import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { updateView, syncLocationHash } from "./redux/actions/portableState";
import request from "request";


request('https://cesium-3d-tiles.storage.googleapis.com/kangas-weup/Tietoa_weup_20191002_1708.json', function(err, res, body) {  
  if(err) {
    console.log("Gloud state failed:",err);
  }
  else if(body) {
    updateStateFromCloud(JSON.parse(body));
  }
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

//This is a hacky way to host the state in the cloud
//@TODO: A real database solution needs to exist to handle loading of all assets
const updateStateFromCloud = (value) => {
  store.dispatch({
    type: "SET_STATE_FROM_CLOUD",
    payload: value
  });
};

window.updateReactState = (value) => {
  store.dispatch({
    type: "VIEW_UPDATED",
    payload: value
  });
};

window.setHotspotCoordinates = (value) => {
  store.dispatch({
    type: "SET_HOTSPOT_COORDINATES",
    payload: value
  });
};

window.setKrpanoCoordinates = (value) => {
  store.dispatch({
    type: "SET_KRPANO_COORDINATES",
    payload: value
  });
  syncLocationHash();
};

window.setNewScene = (value) => {
  store.dispatch({
    type: "SET_KRPANO_SCENE",
    payload: value
  });
  syncLocationHash();
};

window.setNewTime = (value) => {
  store.dispatch({
    type: "SET_CESIUM_TIME",
    payload: value
  });
  syncLocationHash();
};

window.requestState = () => {
  updateView();
};

window.onhashchange = () => {
  console.log("Hash change detected");
  if (window.assetsLoaded) {
  store.dispatch({
    type: "NEW_HASH",
    payload: window.location.hash
  });
  updateView();
};
};

window.rotateToSpotDone = (value) => {
  store.dispatch({ type: "ROTATE_UPDATED", payload: value });
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
