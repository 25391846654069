import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { confirmAlert } from "react-confirm-alert";

import {
  onHoverSubCategory,
  unHoverSubCategory
} from "../redux/actions/notifications";

import {
  setSelectedSubcategory,
  addSubCategory,
  patchSubCategory,
  removeSubCategory
} from "../redux/actions/portableState";

import styles from "./sub_category.module.scss";
import { typesIcon } from "../types_icon";
import editIcon from "../assets/icons/edit.svg";
import deleteIcon from "../assets/icons/delete.svg";

class SubCategory extends Component {
  state = {
    editSubCategoryName: false,
    subCategoryName: "",
    subCategoryType: ""
  };

  onChoseSubCategory = (subcategory) => {
    this.props.setSelectedSubcategory(subcategory.id);
  };

  removeSubCategory = (id) => {
    this.props.removeSubCategory({ id });
  };

  deleteSubCategoryMessage = (id) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want remove "${this.props.Data.name}"`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this.removeSubCategory(id)
        },
        { label: "No" }
      ]
    });
  };

  showEditSubCategoryName = () => {
    this.setState({
      editSubCategoryName: true,
      subCategoryName: this.props.Data.name,
      subCategoryType: this.props.Data.type
    });
  };

  hideEditSubCategoryName = () => {
    this.setState({
      editSubCategoryName: false
    });
  };

  handleOnChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  submitSubCategoryName = (e) => {
    e.preventDefault();
    const { subCategoryName, subCategoryType } = this.state;
    const { Data } = this.props;
    const { id } = Data;
    console.log("id", id);
    const patchData = { id, name: subCategoryName, type: subCategoryType };
    this.props.patchSubCategory(patchData);
    this.hideEditSubCategoryName();
  };

  render() {
    const {
      editSubCategoryName,
      subCategoryName,
      subCategoryType
    } = this.state;
    const { Data, chosenSubCategory, subCategoryHover, dev_mode } = this.props;
    return (
      <div>
        {!editSubCategoryName && (
          <div className={styles.sub_category}>
            <div
              className={classNames(styles.sub_category_name, {
                [styles.sub_category_name_hover]:
                  subCategoryHover === Data.name ||
                  chosenSubCategory === Data.id
              })}
              onClick={() => this.onChoseSubCategory(Data)}
              onMouseEnter={() => this.props.onHoverSubCategory(Data.name)}
              onMouseLeave={() => this.props.unHoverSubCategory(Data.name)}>
              <img
                style={{ paddingRight: "4px" }}
                width="20"
                height="20"
                src={typesIcon(Data.icon)}
                alt="placeholder"
              />
              <span>{Data.name}</span>
            </div>

            {dev_mode && (
              <div className={styles.sub_category_icon}>
                <img
                  onClick={this.showEditSubCategoryName}
                  src={editIcon}
                  alt="edit sub category"
                />
                <img
                  onClick={() => this.deleteSubCategoryMessage(Data.id)}
                  src={deleteIcon}
                  alt="delete sub category"
                />
              </div>
            )}
          </div>
        )}
        {editSubCategoryName && (
          <form
            onSubmit={this.submitSubCategoryName}
            className={styles.sub_category_form}>
            <label htmlFor="subCategoryName">Name</label>
            <input
              onChange={this.handleOnChange}
              id="subCategoryName"
              name="subCategoryName"
              className={styles.sub_category_input}
              value={subCategoryName}
            />
            <label htmlFor="subCategoryType">Type</label>
            <input
              onChange={this.handleOnChange}
              id="subCategoryType"
              className={styles.sub_category_input}
              value={subCategoryType}
            />
            <button type="submit" className={styles.sub_category_submit}>
              Save
            </button>
            <button
              onClick={this.hideEditSubCategoryName}
              className={styles.sub_category_cancel}>
              Cancel
            </button>
          </form>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dev_mode: state.notifications.dev_mode,
    chosenCategory: state.portableState.activeCategory,
    chosenSubCategory: state.portableState.activeSubcategory,
    portableState: state.portableState,
    subCategoryHover: state.notifications.subCategoryHover
  };
}

export default connect(
  mapStateToProps,
  {
    setSelectedSubcategory,
    onHoverSubCategory,
    unHoverSubCategory,
    addSubCategory,
    patchSubCategory,
    removeSubCategory
  }
)(SubCategory);
