import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import SubCategory from "../SubCategory";
import styles from "./category.module.scss";

import add_circle from "../assets/icons/add_circle_outline.svg";
import editIcon from "../assets/icons/edit.svg";
import deleteIcon from "../assets/icons/delete.svg";
import {
  patchCategory,
  removeCategory,
  addSubCategory
} from "../redux/actions/portableState";
class Category extends Component {
  state = {
    editCategoryName: false,
    categoryName: "",
    addedSubCategoryName: "",
    addedSubCategoryType: "",
    showAddSubCategory: false
  };

  getSubCategories = (subCategories) => {
    const types = _.uniq(Object.values(subCategories).map((item) => item.type));
    return types.map((item, i) => (
      <div key={i}>
        <div className={styles.type}>{item}</div>
        {this.getSubCategoriesByTypes(item, subCategories)}
      </div>
    ));
  };

  getSubCategoriesByTypes = (type, subCategories) => {
    return Object.values(subCategories).map((item, i) => {
      if (item.type === type) {
        return (
          <SubCategory
            key={i}
            Data={item}
            onChoseSubCategory={this.onChoseSubCategory}
          />
        );
      }
      return null;
    });
  };

  showEditCategoryName = () => {
    const {
      Data: { name, id },
      chosenCategory,
      onChoseCategory
    } = this.props;
    this.setState({
      editCategoryName: !this.state.editCategoryName,
      categoryName: name
    });
    if (chosenCategory !== id) onChoseCategory(id);
  };

  hideEditCategoryName = () => {
    this.setState({ editCategoryName: !this.state.editCategoryName });
  };

  handleOnChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  submitCategoryName = (event) => {
    event.preventDefault();
    const { categoryName } = this.state;
    const {
      Data: { id }
    } = this.props;
    const patchData = { id, name: categoryName };
    this.props.patchCategory({ category: patchData });
    this.showEditCategoryName();
  };

  removeCategory = () => {
    const {
      Data: { id }
    } = this.props;
    this.props.removeCategory({ id });
  };

  deleteCategoryMessage = () => {
    const { name } = this.props.Data;
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want remove "${name} Category"`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this.removeCategory()
        },
        { label: "No" }
      ]
    });
  };

  showNewSubCategoryName = () => {
    this.setState({
      showAddSubCategory: true,
      addedSubCategoryName: "",
      addedSubCategoryType: ""
    });
    const {
      Data: { id },
      chosenCategory,
      onChoseCategory
    } = this.props;
    if (chosenCategory !== id) onChoseCategory(id);
  };

  hideNewSubCategoryName = () => {
    this.setState({ showAddSubCategory: false });
  };

  submitNewSubCategory = (e) => {
    e.preventDefault();
    const { Data } = this.props;
    
    const { addedSubCategoryName, addedSubCategoryType } = this.state;

    if (addedSubCategoryName && addedSubCategoryType) {
      const newSub = {
        name: addedSubCategoryName,
        type: addedSubCategoryType
        // data: [] // Not needed in the new shema? Add placeholders in the template in the store reducer.
      };
      this.props.addSubCategory({ categoryId: Data.id, subcategory: newSub });
      this.hideNewSubCategoryName();
    }
  };

  render() {
    if (!this.props.Data) {
      return "";
    }
    const {
      Data: { name, subcategories, id },
      chosenCategory,
      onChoseCategory,
      dev_mode
    } = this.props;
    const getSubCategories = this.getSubCategories(subcategories);
    return (
      <div
        className={classNames({
          [styles.open_category]: chosenCategory === id
        })}
      >
        <div className={styles.category_name}>
          {!this.state.editCategoryName && (
            <span
              className={styles.category_title}
              onClick={() => onChoseCategory(id)}
            >
              {name}
            </span>
          )}
          {this.state.editCategoryName && (
            <form onSubmit={this.submitCategoryName}>
              <input
                className={styles.category_input}
                id="categoryName"
                value={this.state.categoryName}
                onChange={this.handleOnChange}
              />
              <button type="submit" className={styles.category_submit}>
                Save
              </button>
              <button
                onClick={this.hideEditCategoryName}
                className={styles.category_cancel}
              >
                Cancel
              </button>
            </form>
          )}
          {dev_mode && !this.state.editCategoryName && (
            <div className={styles.category_icons}>
              <img
                onClick={this.showNewSubCategoryName}
                src={add_circle}
                alt="add sub category"
              />
              <img
                onClick={this.showEditCategoryName}
                src={editIcon}
                alt="edit sub category"
              />
              <img
                onClick={this.deleteCategoryMessage}
                src={deleteIcon}
                alt="delete sub category"
              />
            </div>
          )}

          {this.state.showAddSubCategory && (
            <form
              onSubmit={this.submitNewSubCategory}
              className={styles.sub_category_form}
            >
              <label htmlFor="addedSubCategoryName">Name</label>
              <input
                onChange={this.handleOnChange}
                id="addedSubCategoryName"
                className={styles.sub_category_input}
                value={this.state.addedSubCategoryName}
              />
              <label htmlFor="addedSubCategoryType">Type</label>
              <input
                onChange={this.handleOnChange}
                id="addedSubCategoryType"
                className={styles.sub_category_input}
                value={this.state.addedSubCategoryType}
              />
              <button type="submit" className={styles.sub_category_submit}>
                Save
              </button>
              <button
                onClick={this.hideNewSubCategoryName}
                className={styles.sub_category_cancel}
              >
                Cancel
              </button>
            </form>
          )}
        </div>
        <div
          className={classNames(styles.sub_categories, {
            [styles.hide]: chosenCategory !== id
          })}
        >
          {getSubCategories}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dev_mode: state.notifications.dev_mode,
    chosenCategory: state.portableState.activeCategory,
    portableState: state.portableState,
    notifications: state.notifications
  };
}

export default connect(
  mapStateToProps,
  { patchCategory, removeCategory, addSubCategory }
)(Category);
