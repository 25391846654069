import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Category from "../Category";
import { enableDevMode } from "../redux/actions/notifications";
import {
  setSelectedCategory,
  setSelectedSubcategory,
  filterJsonPortableState
} from "../redux/actions/portableState";

import { addCategory } from "../redux/actions/portableState";

import styles from "./categories.module.scss";
import expandLess from "../assets/icons/expand_less.svg";
import expandMore from "../assets/icons/expand_more.svg";
import filterList from "../assets/icons/filter_list.svg";
import add_circle from "../assets/icons/add_circle_outline.svg";
import exportStateIcon from "../assets/icons/baseline-get_app-24px.svg";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideFilters: false,
      categoryInput: false,
      newCat: ""
    };
  }

  getCategoriesList = (portableState) => {
    if (portableState.categories) {
      return Object.values(portableState.categories).map((item, i) => (
        <Category key={i} Data={item} onChoseCategory={this.setSelectedCategory} />
      ));
    } else {
      return null;
    }
  };

  setSelectedCategory = (newChosen) => {
    if (this.props.chosenCategory !== newChosen) {
      this.props.setSelectedCategory(newChosen);
    } else {
      this.props.setSelectedCategory(null);
      this.props.setSelectedSubcategory(null);
    }
  };

  // Run Dev mode on ctrl + shift + 1

  keydownHandler = (e) => {
    if (e.shiftKey && e.ctrlKey && e.keyCode === 49) this.toggleDevMode();
  };

  componentDidMount() {
    const { portableState, setSelectedCategory } = this.props;

    if (!this.state.hideFilters) {
      if (portableState.categories)
        setSelectedCategory(Object.values(portableState.categories)[0].id);
    }

    document.addEventListener("keydown", this.keydownHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keydownHandler);
  }

  toggleFilters = () => {
    this.setState({ hideFilters: !this.state.hideFilters });

    if (!this.state.hideFilters) {
      this.props.setSelectedCategory(null);
      this.props.setSelectedSubcategory(null);
    }
  };

  toggleDevMode = () => {
    if (this.props.dev_mode) {
      this.props.enableDevMode(false);
    } else this.props.enableDevMode(true);
  };

  showCategoryInput = () => {
    this.setState({ categoryInput: true });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { newCat } = this.state;

    const newCategory = {
      name: newCat,
      subcategories: {}
    };
    this.props.addCategory(newCategory);
    this.setState({ categoryInput: false, newCat: "" });
  };

  handleOnChange = (e) => {
    this.setState({ newCat: e.target.value });
  };

  render() {
    const getDownloadPortableStateButton = () => {
      const jsonString = filterJsonPortableState(this.props.portableState);
      return (
        <a
          href={jsonString}
          download="Tietoa_weup.json"
          style={{ height: "20px" }}
        >
          <img src={exportStateIcon} alt="Export State" />
        </a>
      );
    };

    const { hideFilters, categoryInput, newCat } = this.state;
    const categoriesJSX = this.getCategoriesList(this.props.portableState);
    return (
      <div
        className={classNames(styles.Categories, {
          [styles.Categories_open]: !hideFilters
        })}
      >
        <div className={styles.categories_header}>
          <img onClick={this.toggleFilters} src={filterList} alt="filter" />
          <h5 className={styles.categories_header_title}>Kangas – alue-esittely</h5>
          {this.props.dev_mode && (
            <img
              onClick={this.showCategoryInput}
              src={add_circle}
              alt="add_circle"
            />
          )}
          &nbsp;
          {this.props.dev_mode && getDownloadPortableStateButton()}
          &nbsp;
          <img
            onClick={this.toggleFilters}
            src={hideFilters ? expandMore : expandLess}
            alt="expand"
          />
        </div>
        {!hideFilters && (
          <div className={styles.categories_menu}>{categoriesJSX}</div>
        )}
        {this.props.dev_mode && categoryInput && (
          <form onSubmit={this.handleSubmit} className={styles.category_input}>
            <input onChange={this.handleOnChange} value={newCat} />
            <button>Add Category</button>
          </form>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dev_mode: state.notifications.dev_mode,
    chosenCategory: state.portableState.activeCategory,
    portableState: state.portableState
  };
}

export default connect(
  mapStateToProps,
  {
    setSelectedCategory,
    setSelectedSubcategory,
    enableDevMode,
    addCategory
  }
)(Categories);
